export default [
    {
        id: 131300,
        name: "Acabado de productos textiles"
    },
    {
        id: 900002,
        name: "Actividades artísticas realizadas por bandas de música, compañías de teatro, circenses y similares"
    },
    {
        id: 641910,
        name: "Actividades bancarias"
    },
    {
        id: 811000,
        name: "Actividades combinadas de apoyo a instalaciones"
    },
    {
        id: 821100,
        name: "Actividades combinadas de servicios administrativos de oficina"
    },
    {
        id: 829120,
        name: "Actividades de agencias de calificación crediticia"
    },
    {
        id: 829110,
        name: "Actividades de agencias de cobro"
    },
    {
        id: 781000,
        name: "Actividades de agencias de empleo"
    },
    {
        id: 782000,
        name: "Actividades de agencias de empleo temporal (incluye empresas de servicios transitorios)"
    },
    {
        id: 639100,
        name: "Actividades de agencias de noticias"
    },
    {
        id: 791100,
        name: "Actividades de agencias de viajes"
    },
    {
        id: 749004,
        name: "Actividades de agencias y agentes de representación de actores, deportistas y otras figuras públicas"
    },
    {
        id: 662200,
        name: "Actividades de agentes y corredores de seguros"
    },
    {
        id: 16100,
        name: "Actividades de apoyo a la agricultura"
    },
    {
        id: 855000,
        name: "Actividades de apoyo a la enseñanza"
    },
    {
        id: 16200,
        name: "Actividades de apoyo a la ganadería"
    },
    {
        id: 99001,
        name: "Actividades de apoyo para la explotación de otras minas y canteras prestados por empresas"
    },
    {
        id: 99002,
        name: "Actividades de apoyo para la explotación de otras minas y canteras prestados por profesionales"
    },
    {
        id: 91001,
        name: "Actividades de apoyo para la extracción de petróleo y gas natural prestados por empresas"
    },
    {
        id: 91002,
        name: "Actividades de apoyo para la extracción de petróleo y gas natural prestados por profesionales"
    },
    {
        id: 900003,
        name: "Actividades de artistas realizadas de forma independiente: actores, músicos, escritores, entre otros"
    },
    {
        id: 881000,
        name: "Actividades de asistencia social sin alojamiento para personas de edad y personas con discapacidad"
    },
    {
        id: 941100,
        name: "Actividades de asociaciones empresariales y de empleadores"
    },
    {
        id: 941200,
        name: "Actividades de asociaciones profesionales"
    },
    {
        id: 871000,
        name: "Actividades de atención de enfermería en instituciones"
    },
    {
        id: 872000,
        name: "Actividades de atención en instituciones para personas con discapacidad mental y toxicómanos"
    },
    {
        id: 873000,
        name: "Actividades de atención en instituciones para personas de edad y personas con discapacidad física"
    },
    {
        id: 910100,
        name: "Actividades de bibliotecas y archivos"
    },
    {
        id: 822000,
        name: "Actividades de call-center"
    },
    {
        id: 661901,
        name: "Actividades de cámaras de compensación"
    },
    {
        id: 552000,
        name: "Actividades de camping y de parques para casas rodantes"
    },
    {
        id: 661204,
        name: "Actividades de casas de cambio y operadores de divisa"
    },
    {
        id: 920010,
        name: "Actividades de casinos de juegos"
    },
    {
        id: 949901,
        name: "Actividades de centros de madres"
    },
    {
        id: 862010,
        name: "Actividades de centros de salud municipalizados (servicios de salud pública)"
    },
    {
        id: 661904,
        name: "Actividades de clasificadoras de riesgo"
    },
    {
        id: 750001,
        name: "Actividades de clínicas veterinarias"
    },
    {
        id: 931201,
        name: "Actividades de clubes de fútbol amateur y profesional"
    },
    {
        id: 949902,
        name: "Actividades de clubes sociales"
    },
    {
        id: 702000,
        name: "Actividades de consultoría de gestión"
    },
    {
        id: 620200,
        name: "Actividades de consultoría de informática y de gestión de instalaciones informáticas"
    },
    {
        id: 692000,
        name: "Actividades de contabilidad, teneduría de libros y auditoría; consultoría fiscal"
    },
    {
        id: 649202,
        name: "Actividades de crédito prendario"
    },
    {
        id: 842200,
        name: "Actividades de defensa"
    },
    {
        id: 390000,
        name: "Actividades de descontaminación y otros servicios de gestión de desechos"
    },
    {
        id: 563001,
        name: "Actividades de discotecas y cabaret (night club), con predominio del servicio de bebidas"
    },
    {
        id: 741001,
        name: "Actividades de diseño de vestuario"
    },
    {
        id: 741002,
        name: "Actividades de diseño y decoración de interiores"
    },
    {
        id: 591300,
        name: "Actividades de distribución de películas cinematográficas, videos y programas de televisión"
    },
    {
        id: 102050,
        name: "Actividades de elaboración y conservación de pescado, realizadas en barcos factoría"
    },
    {
        id: 829200,
        name: "Actividades de envasado y empaquetado"
    },
    {
        id: 591400,
        name: "Actividades de exhibición de películas cinematográficas y cintas de video"
    },
    {
        id: 592000,
        name: "Actividades de grabación de sonido y edición de música"
    },
    {
        id: 861020,
        name: "Actividades de hospitales y clínicas privadas"
    },
    {
        id: 861010,
        name: "Actividades de hospitales y clínicas públicas"
    },
    {
        id: 551001,
        name: "Actividades de hoteles"
    },
    {
        id: 803000,
        name: "Actividades de investigación (incluye actividades de investigadores y detectives privados)"
    },
    {
        id: 651220,
        name: "Actividades de isapres"
    },
    {
        id: 910300,
        name: "Actividades de jardines botánicos, zoológicos y reservas naturales"
    },
    {
        id: 841100,
        name: "Actividades de la administración pública en general"
    },
    {
        id: 869010,
        name: "Actividades de laboratorios clínicos y bancos de sangre"
    },
    {
        id: 325001,
        name: "Actividades de laboratorios dentales"
    },
    {
        id: 970000,
        name: "Actividades de los hogares como empleadores de personal doméstico"
    },
    {
        id: 842300,
        name: "Actividades de mantenimiento del orden público y de seguridad"
    },
    {
        id: 532000,
        name: "Actividades de mensajería"
    },
    {
        id: 551002,
        name: "Actividades de moteles"
    },
    {
        id: 910200,
        name: "Actividades de museos, gestión de lugares y edificios históricos"
    },
    {
        id: 701000,
        name: "Actividades de oficinas principales"
    },
    {
        id: 791200,
        name: "Actividades de operadores turísticos"
    },
    {
        id: 949200,
        name: "Actividades de organizaciones políticas"
    },
    {
        id: 949100,
        name: "Actividades de organizaciones religiosas"
    },
    {
        id: 990000,
        name: "Actividades de organizaciones y órganos extraterritoriales"
    },
    {
        id: 949909,
        name: "Actividades de otras asociaciones n.c.p."
    },
    {
        id: 931209,
        name: "Actividades de otros clubes deportivos n.c.p."
    },
    {
        id: 813000,
        name: "Actividades de paisajismo, servicios de jardinería y servicios conexos"
    },
    {
        id: 932100,
        name: "Actividades de parques de atracciones y parques temáticos"
    },
    {
        id: 712001,
        name: "Actividades de plantas de revisión técnica para vehículos automotores"
    },
    {
        id: 591200,
        name: "Actividades de postproducción de películas cinematográficas, videos y programas de televisión"
    },
    {
        id: 591100,
        name: "Actividades de producción de películas cinematográficas, videos y programas de televisión"
    },
    {
        id: 620100,
        name: "Actividades de programación informática"
    },
    {
        id: 559001,
        name: "Actividades de residenciales para estudiantes y trabajadores "
    },
    {
        id: 551003,
        name: "Actividades de residenciales para turistas"
    },
    {
        id: 561000,
        name: "Actividades de restaurantes y de servicio móvil de comidas"
    },
    {
        id: 960902,
        name: "Actividades de salones de masajes, baños turcos, saunas, servicio de baños públicos"
    },
    {
        id: 661201,
        name: "Actividades de securitizadoras"
    },
    {
        id: 802000,
        name: "Actividades de servicios de sistemas de seguridad (incluye servicios de cerrajería)"
    },
    {
        id: 181200,
        name: "Actividades de servicios relacionadas con la impresión"
    },
    {
        id: 522200,
        name: "Actividades de servicios vinculadas al transporte acuático"
    },
    {
        id: 522300,
        name: "Actividades de servicios vinculadas al transporte aéreo"
    },
    {
        id: 522190,
        name: "Actividades de servicios vinculadas al transporte terrestre n.c.p."
    },
    {
        id: 942000,
        name: "Actividades de sindicatos"
    },
    {
        id: 642000,
        name: "Actividades de sociedades de cartera"
    },
    {
        id: 750002,
        name: "Actividades de veterinarios, técnicos y otro personal auxiliar, prestados de forma independiente"
    },
    {
        id: 682000,
        name: "Actividades inmobiliarias realizadas a cambio de una retribución o por contrata"
    },
    {
        id: 16300,
        name: "Actividades poscosecha"
    },
    {
        id: 531000,
        name: "Actividades postales"
    },
    {
        id: 32200,
        name: "Acuicultura de agua dulce"
    },
    {
        id: 661100,
        name: "Administración de mercados financieros"
    },
    {
        id: 661902,
        name: "Administración de tarjetas de crédito"
    },
    {
        id: 663093,
        name: "Administradoras de fices (fondos de inversión de capital extranjero)"
    },
    {
        id: 663091,
        name: "Administradoras de fondos de inversión"
    },
    {
        id: 663010,
        name: "Administradoras de fondos de pensiones (afp)"
    },
    {
        id: 663092,
        name: "Administradoras de fondos mutuos"
    },
    {
        id: 663094,
        name: "Administradoras de fondos para la vivienda"
    },
    {
        id: 663099,
        name: "Administradoras de fondos para otros fines n.c.p."
    },
    {
        id: 522910,
        name: "Agencias de aduanas"
    },
    {
        id: 522920,
        name: "Agencias de naves"
    },
    {
        id: 661203,
        name: "Agentes de valores"
    },
    {
        id: 681011,
        name: "Alquiler de bienes inmuebles amoblados o con equipos y maquinarias"
    },
    {
        id: 772200,
        name: "Alquiler de cintas de video y discos"
    },
    {
        id: 773001,
        name: "Alquiler de equipos de transporte sin operario, excepto vehículos automotores"
    },
    {
        id: 773002,
        name: "Alquiler de maquinaria y equipo agropecuario, forestal, de construcción e ing. civil, sin operarios"
    },
    {
        id: 773003,
        name: "Alquiler de maquinaria y equipo de oficina, sin operarios (sin servicio administrativo)"
    },
    {
        id: 772900,
        name: "Alquiler de otros efectos personales y enseres domésticos (incluye mobiliario para eventos)"
    },
    {
        id: 773009,
        name: "Alquiler de otros tipos de maquinarias y equipos sin operario n.c.p."
    },
    {
        id: 771000,
        name: "Alquiler de vehículos automotores sin chofer"
    },
    {
        id: 772100,
        name: "Alquiler y arrendamiento de equipo recreativo y deportivo"
    },
    {
        id: 14901,
        name: "Apicultura"
    },
    {
        id: 774000,
        name: "Arrendamiento de propiedad intelectual y similares, excepto obras protegidas por derechos de autor"
    },
    {
        id: 161000,
        name: "Aserrado y acepilladura de madera"
    },
    {
        id: 749001,
        name: "Asesoría y gestión en la compra o venta de pequeñas y medianas empresas"
    },
    {
        id: 641100,
        name: "Banca central"
    },
    {
        id: 649203,
        name: "Cajas de compensación"
    },
    {
        id: 360000,
        name: "Captación, tratamiento y distribución de agua"
    },
    {
        id: 17000,
        name: "Caza ordinaria y mediante trampas y actividades de servicios conexas"
    },
    {
        id: 862022,
        name: "Centros de atención odontológica privados (establecimientos de atención ambulatoria)"
    },
    {
        id: 619010,
        name: "Centros de llamados y centros de acceso a internet"
    },
    {
        id: 862021,
        name: "Centros médicos privados (establecimientos de atención ambulatoria)"
    },
    {
        id: 681012,
        name: "Compra, venta y alquiler (excepto amoblados) de inmuebles"
    },
    {
        id: 949904,
        name: "Consejo de administración de edificios y condominios"
    },
    {
        id: 691003,
        name: "Conservador de bienes raíces"
    },
    {
        id: 301100,
        name: "Construcción de buques, embarcaciones menores y estructuras flotantes"
    },
    {
        id: 421000,
        name: "Construcción de carreteras y líneas de ferrocarril"
    },
    {
        id: 410020,
        name: "Construcción de edificios para uso no residencial"
    },
    {
        id: 410010,
        name: "Construcción de edificios para uso residencial"
    },
    {
        id: 301200,
        name: "Construcción de embarcaciones de recreo y de deporte"
    },
    {
        id: 429000,
        name: "Construcción de otras obras de ingeniería civil"
    },
    {
        id: 422000,
        name: "Construcción de proyectos de servicio público"
    },
    {
        id: 661202,
        name: "Corredores de bolsa"
    },
    {
        id: 461002,
        name: "Corretaje al por mayor de ganado"
    },
    {
        id: 461001,
        name: "Corretaje al por mayor de productos agrícolas"
    },
    {
        id: 239600,
        name: "Corte, talla y acabado de la piedra"
    },
    {
        id: 14601,
        name: "Cría de aves de corral para la producción de carne"
    },
    {
        id: 14602,
        name: "Cría de aves de corral para la producción de huevos"
    },
    {
        id: 14200,
        name: "Cría de caballos y otros equinos"
    },
    {
        id: 14420,
        name: "Cría de cabras (caprinos)"
    },
    {
        id: 14500,
        name: "Cría de cerdos"
    },
    {
        id: 14102,
        name: "Cría de ganado bovino para la producción de carne o como ganado reproductor"
    },
    {
        id: 14101,
        name: "Cría de ganado bovino para la producción lechera"
    },
    {
        id: 14300,
        name: "Cría de llamas, alpacas, vicuñas, guanacos y otros camélidos"
    },
    {
        id: 14909,
        name: "Cría de otros animales n.c.p."
    },
    {
        id: 14410,
        name: "Cría de ovejas (ovinos)"
    },
    {
        id: 11200,
        name: "Cultivo de arroz"
    },
    {
        id: 11103,
        name: "Cultivo de avena"
    },
    {
        id: 11302,
        name: "Cultivo de camotes"
    },
    {
        id: 11400,
        name: "Cultivo de caña de azúcar"
    },
    {
        id: 11104,
        name: "Cultivo de cebada"
    },
    {
        id: 12300,
        name: "Cultivo de cítricos"
    },
    {
        id: 12801,
        name: "Cultivo de especias"
    },
    {
        id: 11901,
        name: "Cultivo de flores"
    },
    {
        id: 12400,
        name: "Cultivo de frutas de pepita y de hueso"
    },
    {
        id: 12200,
        name: "Cultivo de frutas tropicales y subtropicales (incluye el cultivo de paltas)"
    },
    {
        id: 12600,
        name: "Cultivo de frutos oleaginosos (incluye el cultivo de aceitunas)"
    },
    {
        id: 11306,
        name: "Cultivo de hortalizas y melones "
    },
    {
        id: 11107,
        name: "Cultivo de lupino"
    },
    {
        id: 11102,
        name: "Cultivo de maíz"
    },
    {
        id: 11108,
        name: "Cultivo de otras legumbres (excepto porotos y lupino)"
    },
    {
        id: 12900,
        name: "Cultivo de otras plantas perennes"
    },
    {
        id: 11105,
        name: "Cultivo de otros cereales (excepto trigo, maíz, avena y cebada)"
    },
    {
        id: 12502,
        name: "Cultivo de otros frutos y nueces de árboles y arbustos"
    },
    {
        id: 11303,
        name: "Cultivo de otros tubérculos (excepto papas y camotes)"
    },
    {
        id: 11301,
        name: "Cultivo de papas"
    },
    {
        id: 12802,
        name: "Cultivo de plantas aromáticas, medicinales y farmacéuticas"
    },
    {
        id: 12700,
        name: "Cultivo de plantas con las que se preparan bebidas (incluye el cultivo de café, té y mate)"
    },
    {
        id: 11600,
        name: "Cultivo de plantas de fibra"
    },
    {
        id: 13000,
        name: "Cultivo de plantas vivas incluida la producción en viveros (excepto viveros forestales)"
    },
    {
        id: 11106,
        name: "Cultivo de porotos"
    },
    {
        id: 15000,
        name: "Cultivo de productos agrícolas en combinación con la cría de animales (explotación mixta)"
    },
    {
        id: 11304,
        name: "Cultivo de remolacha azucarera"
    },
    {
        id: 11111,
        name: "Cultivo de semillas de cereales, legumbres y oleaginosas (excepto semillas de raps y maravilla)"
    },
    {
        id: 12501,
        name: "Cultivo de semillas de frutas"
    },
    {
        id: 11305,
        name: "Cultivo de semillas de hortalizas"
    },
    {
        id: 11110,
        name: "Cultivo de semillas de maravilla (girasol)"
    },
    {
        id: 11109,
        name: "Cultivo de semillas de raps"
    },
    {
        id: 11500,
        name: "Cultivo de tabaco"
    },
    {
        id: 11101,
        name: "Cultivo de trigo"
    },
    {
        id: 12111,
        name: "Cultivo de uva destinada a la producción de pisco y aguardiente"
    },
    {
        id: 12112,
        name: "Cultivo de uva destinada a la producción de vino"
    },
    {
        id: 12120,
        name: "Cultivo de uva para mesa"
    },
    {
        id: 32120,
        name: "Cultivo, reproducción y manejo de algas marinas"
    },
    {
        id: 11903,
        name: "Cultivos de semillas de flores; cultivo de semillas de plantas forrajeras"
    },
    {
        id: 11902,
        name: "Cultivos forrajeros en praderas mejoradas o sembradas; cultivos suplementarios forrajeros"
    },
    {
        id: 32110,
        name: "Cultivo y crianza de peces marinos"
    },
    {
        id: 151100,
        name: "Curtido y adobo de cueros; adobo y teñido de pieles"
    },
    {
        id: 431100,
        name: "Demolición"
    },
    {
        id: 812901,
        name: "Desratización, desinfección y exterminio de plagas no agrícolas"
    },
    {
        id: 110120,
        name: "Destilación, rectificación y mezclas de bebidas alcohólicas; excepto pisco"
    },
    {
        id: 351030,
        name: "Distribución de energía eléctrica"
    },
    {
        id: 581300,
        name: "Edición de diarios, revistas y otras publicaciones periódicas"
    },
    {
        id: 581200,
        name: "Edición de directorios y listas de correo"
    },
    {
        id: 581100,
        name: "Edición de libros"
    },
    {
        id: 582000,
        name: "Edición de programas informáticos"
    },
    {
        id: 104000,
        name: "Elaboración de aceites y grasas de origen vegetal y animal (excepto elaboración de mantequilla)"
    },
    {
        id: 106200,
        name: "Elaboración de almidones y productos derivados del almidón"
    },
    {
        id: 107200,
        name: "Elaboración de azúcar"
    },
    {
        id: 110300,
        name: "Elaboración de bebidas malteadas y de malta"
    },
    {
        id: 110401,
        name: "Elaboración de bebidas no alcohólicas"
    },
    {
        id: 107300,
        name: "Elaboración de cacao, chocolate y de productos de confitería"
    },
    {
        id: 120001,
        name: "Elaboración de cigarros y cigarrillos"
    },
    {
        id: 107500,
        name: "Elaboración de comidas y platos preparados envasados, rotulados y con información nutricional"
    },
    {
        id: 353002,
        name: "Elaboración de hielo (excepto fabricación de hielo seco)"
    },
    {
        id: 107902,
        name: "Elaboración de levaduras naturales o artificiales"
    },
    {
        id: 107400,
        name: "Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares"
    },
    {
        id: 107909,
        name: "Elaboración de otros productos alimenticios n.c.p."
    },
    {
        id: 106109,
        name: "Elaboración de otros productos de molinería n.c.p."
    },
    {
        id: 120009,
        name: "Elaboración de otros productos de tabaco n.c.p."
    },
    {
        id: 108000,
        name: "Elaboración de piensos preparados para animales"
    },
    {
        id: 110110,
        name: "Elaboración de pisco (industrias pisqueras)"
    },
    {
        id: 107100,
        name: "Elaboración de productos de panadería y pastelería"
    },
    {
        id: 105000,
        name: "Elaboración de productos lácteos"
    },
    {
        id: 107901,
        name: "Elaboración de té, café, mate e infusiones de hierbas"
    },
    {
        id: 107903,
        name: "Elaboración de vinagres, mostazas, mayonesas y condimentos en general"
    },
    {
        id: 110200,
        name: "Elaboración de vinos"
    },
    {
        id: 101020,
        name: "Elaboración y conservación de carne y productos cárnicos"
    },
    {
        id: 102040,
        name: "Elaboración y conservación de crustáceos, moluscos y otros productos acuáticos, en plantas en tierra"
    },
    {
        id: 103000,
        name: "Elaboración y conservación de frutas, legumbres y hortalizas"
    },
    {
        id: 102030,
        name: "Elaboración y conservación de otros pescados, en plantas en tierra (excepto barcos factoría)"
    },
    {
        id: 102020,
        name: "Elaboración y conservación de salmónidos"
    },
    {
        id: 102060,
        name: "Elaboración y procesamiento de algas"
    },
    {
        id: 661903,
        name: "Empresas de asesoría y consultoría en inversión financiera; sociedades de apoyo al giro"
    },
    {
        id: 711002,
        name: "Empresas de servicios de ingeniería y actividades conexas de consultoría técnica "
    },
    {
        id: 854200,
        name: "Enseñanza cultural"
    },
    {
        id: 854100,
        name: "Enseñanza deportiva y recreativa"
    },
    {
        id: 850021,
        name: "Enseñanza preescolar privada"
    },
    {
        id: 850011,
        name: "Enseñanza preescolar pública"
    },
    {
        id: 854901,
        name: "Enseñanza preuniversitaria"
    },
    {
        id: 850022,
        name: "Enseñanza primaria, secundaria científico humanista y técnico profesional privada"
    },
    {
        id: 850012,
        name: "Enseñanza primaria, secundaria científico humanista y técnico profesional pública"
    },
    {
        id: 853202,
        name: "Enseñanza superior en centros de formación técnica"
    },
    {
        id: 853201,
        name: "Enseñanza superior en institutos profesionales"
    },
    {
        id: 853120,
        name: "Enseñanza superior en universidades privadas"
    },
    {
        id: 853110,
        name: "Enseñanza superior en universidades públicas"
    },
    {
        id: 732000,
        name: "Estudios de mercado y encuestas de opinión pública"
    },
    {
        id: 370000,
        name: "Evacuación y tratamiento de aguas servidas"
    },
    {
        id: 662100,
        name: "Evaluación de riesgos y daños (incluye actividades de liquidadores de seguros)"
    },
    {
        id: 522120,
        name: "Explotación de estacionamientos de vehículos automotores y parquímetros"
    },
    {
        id: 521001,
        name: "Explotación de frigoríficos para almacenamiento y depósito"
    },
    {
        id: 101019,
        name: "Explotación de mataderos de aves y de otros tipos de animales n.c.p."
    },
    {
        id: 101011,
        name: "Explotación de mataderos de bovinos, ovinos, equinos, caprinos, porcinos y camélidos "
    },
    {
        id: 89900,
        name: "Explotación de otras minas y canteras n.c.p."
    },
    {
        id: 522110,
        name: "Explotación de terminales terrestres de pasajeros"
    },
    {
        id: 21001,
        name: "Explotación de viveros forestales"
    },
    {
        id: 51000,
        name: "Extracción de carbón de piedra"
    },
    {
        id: 62000,
        name: "Extracción de gas natural"
    },
    {
        id: 52000,
        name: "Extracción de lignito"
    },
    {
        id: 22000,
        name: "Extracción de madera"
    },
    {
        id: 72992,
        name: "Extracción de manganeso"
    },
    {
        id: 71000,
        name: "Extracción de minerales de hierro"
    },
    {
        id: 72100,
        name: "Extracción de minerales de uranio y torio"
    },
    {
        id: 89190,
        name: "Extracción de minerales para la fabricación de abonos y productos químicos n.c.p."
    },
    {
        id: 72910,
        name: "Extracción de oro y plata"
    },
    {
        id: 72999,
        name: "Extracción de otros minerales metalíferos no ferrosos n.c.p. (excepto zinc, plomo y manganeso)"
    },
    {
        id: 61000,
        name: "Extracción de petróleo crudo"
    },
    {
        id: 81000,
        name: "Extracción de piedra, arena y arcilla"
    },
    {
        id: 89300,
        name: "Extracción de sal"
    },
    {
        id: 89200,
        name: "Extracción de turba"
    },
    {
        id: 72991,
        name: "Extracción de zinc y plomo"
    },
    {
        id: 40000,
        name: "Extracción y procesamiento de cobre"
    },
    {
        id: 89110,
        name: "Extracción y procesamiento de litio"
    },
    {
        id: 201200,
        name: "Fabricación de abonos y compuestos de nitrógeno"
    },
    {
        id: 141003,
        name: "Fabricación de accesorios de vestir"
    },
    {
        id: 303000,
        name: "Fabricación de aeronaves, naves espaciales y maquinaria conexa"
    },
    {
        id: 275000,
        name: "Fabricación de aparatos de uso doméstico"
    },
    {
        id: 264000,
        name: "Fabricación de aparatos electrónicos de consumo"
    },
    {
        id: 252000,
        name: "Fabricación de armas y municiones"
    },
    {
        id: 139200,
        name: "Fabricación de artículos confeccionados de materiales textiles, excepto prendas de vestir"
    },
    {
        id: 259300,
        name: "Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería"
    },
    {
        id: 323000,
        name: "Fabricación de artículos de deporte"
    },
    {
        id: 239500,
        name: "Fabricación de artículos de hormigón, cemento y yeso"
    },
    {
        id: 142000,
        name: "Fabricación de artículos de piel"
    },
    {
        id: 143000,
        name: "Fabricación de artículos de punto y ganchillo"
    },
    {
        id: 309200,
        name: "Fabricación de bicicletas y de sillas de ruedas"
    },
    {
        id: 321200,
        name: "Fabricación de bisutería y artículos conexos"
    },
    {
        id: 273100,
        name: "Fabricación de cables de fibra óptica"
    },
    {
        id: 152000,
        name: "Fabricación de calzado"
    },
    {
        id: 201101,
        name: "Fabricación de carbón vegetal (excepto activado); fabricación de briquetas de carbón vegetal"
    },
    {
        id: 292000,
        name: "Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques"
    },
    {
        id: 170110,
        name: "Fabricación de celulosa y otras pastas de madera"
    },
    {
        id: 239400,
        name: "Fabricación de cemento, cal y yeso"
    },
    {
        id: 281400,
        name: "Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión"
    },
    {
        id: 310009,
        name: "Fabricación de colchones; fabricación de otros muebles n.c.p."
    },
    {
        id: 261000,
        name: "Fabricación de componentes y tableros electrónicos"
    },
    {
        id: 262000,
        name: "Fabricación de computadores y equipo periférico"
    },
    {
        id: 221100,
        name: "Fabricación de cubiertas y cámaras de caucho; recauchutado y renovación de cubiertas de caucho"
    },
    {
        id: 139400,
        name: "Fabricación de cuerdas, cordeles, bramantes y redes"
    },
    {
        id: 273300,
        name: "Fabricación de dispositivos de cableado"
    },
    {
        id: 263000,
        name: "Fabricación de equipo de comunicaciones"
    },
    {
        id: 281600,
        name: "Fabricación de equipo de elevación y manipulación"
    },
    {
        id: 266000,
        name: "Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico"
    },
    {
        id: 265100,
        name: "Fabricación de equipo de medición, prueba, navegación y control"
    },
    {
        id: 281200,
        name: "Fabricación de equipo de propulsión de fluidos"
    },
    {
        id: 274000,
        name: "Fabricación de equipo eléctrico de iluminación"
    },
    {
        id: 202901,
        name: "Fabricación de explosivos y productos pirotécnicos"
    },
    {
        id: 203000,
        name: "Fabricación de fibras artificiales"
    },
    {
        id: 231003,
        name: "Fabricación de fibras de vidrio"
    },
    {
        id: 352020,
        name: "Fabricación de gas; distribución de combustibles gaseosos por tubería, excepto regasificación de gnl"
    },
    {
        id: 251300,
        name: "Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central"
    },
    {
        id: 281800,
        name: "Fabricación de herramientas de mano motorizadas"
    },
    {
        id: 162100,
        name: "Fabricación de hojas de madera para enchapado y tableros a base de madera"
    },
    {
        id: 281500,
        name: "Fabricación de hornos, calderas y quemadores"
    },
    {
        id: 322000,
        name: "Fabricación de instrumentos musicales"
    },
    {
        id: 267000,
        name: "Fabricación de instrumentos ópticos y equipo fotográfico"
    },
    {
        id: 325009,
        name: "Fabricación de instrumentos y materiales médicos, oftalmológicos y odontológicos n.c.p."
    },
    {
        id: 202300,
        name: "Fabricación de jabones y detergentes, preparados para limpiar, perfumes y preparados de tocador"
    },
    {
        id: 321100,
        name: "Fabricación de joyas y artículos conexos"
    },
    {
        id: 324000,
        name: "Fabricación de juegos y juguetes"
    },
    {
        id: 302000,
        name: "Fabricación de locomotoras y material rodante"
    },
    {
        id: 151200,
        name: "Fabricación de maletas, bolsos y artículos similares, artículos de talabartería y guarnicionería"
    },
    {
        id: 282100,
        name: "Fabricación de maquinaria agropecuaria y forestal"
    },
    {
        id: 282300,
        name: "Fabricación de maquinaria metalúrgica"
    },
    {
        id: 282200,
        name: "Fabricación de maquinaria para la conformación de metales y de máquinas herramienta"
    },
    {
        id: 282500,
        name: "Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco"
    },
    {
        id: 282600,
        name: "Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros"
    },
    {
        id: 282400,
        name: "Fabricación de maquinaria para la explotación de minas y canteras y para obras de construcción"
    },
    {
        id: 281700,
        name: "Fabricación de maquinaria y equipo de oficina (excepto computadores y equipo periférico)"
    },
    {
        id: 239200,
        name: "Fabricación de materiales de construcción de arcilla"
    },
    {
        id: 309100,
        name: "Fabricación de motocicletas"
    },
    {
        id: 271000,
        name: "Fabricación de motores, generadores y transformadores eléctricos, aparatos de distribución y control"
    },
    {
        id: 281100,
        name: "Fabricación de motores y turbinas, excepto para aeronaves, vehículos automotores y motocicletas"
    },
    {
        id: 310001,
        name: "Fabricación de muebles principalmente de madera"
    },
    {
        id: 281300,
        name: "Fabricación de otras bombas, compresores, grifos y válvulas"
    },
    {
        id: 201109,
        name: "Fabricación de otras sustancias químicas básicas n.c.p."
    },
    {
        id: 170900,
        name: "Fabricación de otros artículos de papel y cartón"
    },
    {
        id: 273200,
        name: "Fabricación de otros hilos y cables eléctricos"
    },
    {
        id: 221900,
        name: "Fabricación de otros productos de caucho"
    },
    {
        id: 162900,
        name: "Fabricación de otros productos de madera, de artículos de corcho, paja y materiales trenzables"
    },
    {
        id: 239300,
        name: "Fabricación de otros productos de porcelana y de cerámica"
    },
    {
        id: 259900,
        name: "Fabricación de otros productos elaborados de metal n.c.p."
    },
    {
        id: 239900,
        name: "Fabricación de otros productos minerales no metálicos n.c.p."
    },
    {
        id: 202909,
        name: "Fabricación de otros productos químicos n.c.p."
    },
    {
        id: 139900,
        name: "Fabricación de otros productos textiles n.c.p."
    },
    {
        id: 309900,
        name: "Fabricación de otros tipos de equipo de transporte n.c.p."
    },
    {
        id: 279000,
        name: "Fabricación de otros tipos de equipo eléctrico"
    },
    {
        id: 282900,
        name: "Fabricación de otros tipos de maquinaria de uso especial"
    },
    {
        id: 281900,
        name: "Fabricación de otros tipos de maquinaria de uso general"
    },
    {
        id: 170200,
        name: "Fabricación de papel y cartón ondulado y de envases de papel y cartón"
    },
    {
        id: 170190,
        name: "Fabricación de papel y cartón para su posterior uso industrial n.c.p."
    },
    {
        id: 293000,
        name: "Fabricación de partes, piezas y accesorios para vehículos automotores"
    },
    {
        id: 162200,
        name: "Fabricación de partes y piezas de carpintería para edificios y construcciones"
    },
    {
        id: 272000,
        name: "Fabricación de pilas, baterías y acumuladores"
    },
    {
        id: 202200,
        name: "Fabricación de pinturas, barnices y productos de revestimiento, tintas de imprenta y masillas"
    },
    {
        id: 202100,
        name: "Fabricación de plaguicidas y otros productos químicos de uso agropecuario"
    },
    {
        id: 201300,
        name: "Fabricación de plásticos y caucho sintético en formas primarias"
    },
    {
        id: 141002,
        name: "Fabricación de prendas de vestir de cuero natural o artificial"
    },
    {
        id: 141001,
        name: "Fabricación de prendas de vestir de materiales textiles y similares"
    },
    {
        id: 191000,
        name: "Fabricación de productos de hornos de coque"
    },
    {
        id: 192000,
        name: "Fabricación de productos de la refinación del petróleo"
    },
    {
        id: 222000,
        name: "Fabricación de productos de plástico"
    },
    {
        id: 231009,
        name: "Fabricación de productos de vidrio n.c.p."
    },
    {
        id: 210000,
        name: "Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos"
    },
    {
        id: 251100,
        name: "Fabricación de productos metálicos para uso estructural"
    },
    {
        id: 242002,
        name: "Fabricación de productos primarios de aluminio"
    },
    {
        id: 242001,
        name: "Fabricación de productos primarios de cobre"
    },
    {
        id: 242009,
        name: "Fabricación de productos primarios de metales preciosos y de otros metales no ferrosos n.c.p."
    },
    {
        id: 239100,
        name: "Fabricación de productos refractarios"
    },
    {
        id: 162300,
        name: "Fabricación de recipientes de madera"
    },
    {
        id: 251201,
        name: "Fabricación de recipientes de metal para gases comprimidos o licuados"
    },
    {
        id: 265200,
        name: "Fabricación de relojes"
    },
    {
        id: 141004,
        name: "Fabricación de ropa de trabajo"
    },
    {
        id: 268000,
        name: "Fabricación de soportes magnéticos y ópticos"
    },
    {
        id: 251209,
        name: "Fabricación de tanques, depósitos y recipientes de metal n.c.p."
    },
    {
        id: 139300,
        name: "Fabricación de tapices y alfombras"
    },
    {
        id: 139100,
        name: "Fabricación de tejidos de punto y ganchillo"
    },
    {
        id: 291000,
        name: "Fabricación de vehículos automotores"
    },
    {
        id: 304000,
        name: "Fabricación de vehículos militares de combate"
    },
    {
        id: 231002,
        name: "Fabricación de vidrio hueco"
    },
    {
        id: 231001,
        name: "Fabricación de vidrio plano"
    },
    {
        id: 649201,
        name: "Financieras"
    },
    {
        id: 843010,
        name: "Fondo nacional de salud (fonasa) "
    },
    {
        id: 653000,
        name: "Fondos de pensiones"
    },
    {
        id: 643000,
        name: "Fondos y sociedades de inversión y entidades financieras similares"
    },
    {
        id: 259100,
        name: "Forja, prensado, estampado y laminado de metales; pulvimetalurgia"
    },
    {
        id: 821900,
        name: "Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo de oficina"
    },
    {
        id: 949903,
        name: "Fundaciones y corporaciones; asociaciones que promueven actividades culturales o recreativas"
    },
    {
        id: 243100,
        name: "Fundición de hierro y acero"
    },
    {
        id: 243200,
        name: "Fundición de metales no ferrosos"
    },
    {
        id: 351011,
        name: "Generación de energía eléctrica en centrales hidroeléctricas"
    },
    {
        id: 351012,
        name: "Generación de energía eléctrica en centrales termoeléctricas"
    },
    {
        id: 351019,
        name: "Generación de energía eléctrica en otras centrales n.c.p."
    },
    {
        id: 931109,
        name: "Gestión de otras instalaciones deportivas n.c.p."
    },
    {
        id: 931102,
        name: "Gestión de salas de billar; gestión de salas de bolos (bowling)"
    },
    {
        id: 932901,
        name: "Gestión de salas de pool; gestión (explotación) de juegos electrónicos"
    },
    {
        id: 931101,
        name: "Hipódromos"
    },
    {
        id: 181101,
        name: "Impresión de libros"
    },
    {
        id: 241000,
        name: "Industrias básicas de hierro y acero"
    },
    {
        id: 332000,
        name: "Instalación de maquinaria y equipos industriales"
    },
    {
        id: 432200,
        name: "Instalaciones de gasfitería, calefacción y aire acondicionado"
    },
    {
        id: 432100,
        name: "Instalaciones eléctricas"
    },
    {
        id: 843020,
        name: "Instituto de previsión social (ips)"
    },
    {
        id: 721000,
        name: "Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería"
    },
    {
        id: 722000,
        name: "Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades"
    },
    {
        id: 960100,
        name: "Lavado y limpieza, incluida la limpieza en seco, de productos textiles y de piel"
    },
    {
        id: 649100,
        name: "Leasing financiero"
    },
    {
        id: 812100,
        name: "Limpieza general de edificios"
    },
    {
        id: 522400,
        name: "Manipulación de la carga"
    },
    {
        id: 454003,
        name: "Mantenimiento y reparación de motocicletas"
    },
    {
        id: 452002,
        name: "Mantenimiento y reparación de vehículos automotores"
    },
    {
        id: 106102,
        name: "Molienda de arroz; producción de harina de arroz"
    },
    {
        id: 106101,
        name: "Molienda de trigo: producción de harina, sémola y gránulos"
    },
    {
        id: 823000,
        name: "Organización de convenciones y exposiciones comerciales"
    },
    {
        id: 662900,
        name: "Otras actividades auxiliares de las actividades de seguros y fondos de pensiones"
    },
    {
        id: 661909,
        name: "Otras actividades auxiliares de las actividades de servicios financieros n.c.p."
    },
    {
        id: 900009,
        name: "Otras actividades creativas, artísticas y de entretenimiento n.c.p."
    },
    {
        id: 559009,
        name: "Otras actividades de alojamiento n.c.p."
    },
    {
        id: 551009,
        name: "Otras actividades de alojamiento para turistas n.c.p."
    },
    {
        id: 522990,
        name: "Otras actividades de apoyo al transporte n.c.p."
    },
    {
        id: 889000,
        name: "Otras actividades de asistencia social sin alojamiento"
    },
    {
        id: 879000,
        name: "Otras actividades de atención en instituciones"
    },
    {
        id: 649209,
        name: "Otras actividades de concesión de crédito n.c.p."
    },
    {
        id: 783000,
        name: "Otras actividades de dotación de recursos humanos"
    },
    {
        id: 581900,
        name: "Otras actividades de edición"
    },
    {
        id: 932909,
        name: "Otras actividades de esparcimiento y recreativas n.c.p."
    },
    {
        id: 181109,
        name: "Otras actividades de impresión n.c.p."
    },
    {
        id: 920090,
        name: "Otras actividades de juegos de azar y apuestas n.c.p."
    },
    {
        id: 812909,
        name: "Otras actividades de limpieza de edificios e instalaciones industriales n.c.p."
    },
    {
        id: 931909,
        name: "Otras actividades deportivas n.c.p."
    },
    {
        id: 563009,
        name: "Otras actividades de servicio de bebidas n.c.p."
    },
    {
        id: 829900,
        name: "Otras actividades de servicios de apoyo a las empresas n.c.p."
    },
    {
        id: 639900,
        name: "Otras actividades de servicios de información n.c.p."
    },
    {
        id: 649900,
        name: "Otras actividades de servicios financieros, excepto las de seguros y fondos de pensiones n.c.p."
    },
    {
        id: 960909,
        name: "Otras actividades de servicios personales n.c.p."
    },
    {
        id: 620900,
        name: "Otras actividades de tecnología de la información y de servicios informáticos"
    },
    {
        id: 619090,
        name: "Otras actividades de telecomunicaciones n.c.p."
    },
    {
        id: 492290,
        name: "Otras actividades de transporte de pasajeros por vía terrestre n.c.p."
    },
    {
        id: 493090,
        name: "Otras actividades de transporte por tuberías n.c.p."
    },
    {
        id: 492190,
        name: "Otras actividades de transporte urbano y suburbano de pasajeros por vía terrestre n.c.p."
    },
    {
        id: 471990,
        name: "Otras actividades de venta al por menor en comercios no especializados n.c.p."
    },
    {
        id: 479909,
        name: "Otras actividades de venta por menor no realizadas en comercios, puestos de venta o mercados n.c.p."
    },
    {
        id: 439000,
        name: "Otras actividades especializadas de construcción"
    },
    {
        id: 741009,
        name: "Otras actividades especializadas de diseño n.c.p."
    },
    {
        id: 749009,
        name: "Otras actividades profesionales, científicas y técnicas n.c.p."
    },
    {
        id: 329000,
        name: "Otras industrias manufactureras n.c.p."
    },
    {
        id: 432900,
        name: "Otras instalaciones para obras de construcción"
    },
    {
        id: 843090,
        name: "Otros planes de seguridad social de afiliación obligatoria n.c.p."
    },
    {
        id: 521009,
        name: "Otros servicios de almacenamiento y depósito n.c.p."
    },
    {
        id: 24009,
        name: "Otros servicios de apoyo a la silvicultura n.c.p."
    },
    {
        id: 869091,
        name: "Otros servicios de atención de la salud humana prestados por empresas"
    },
    {
        id: 661209,
        name: "Otros servicios de corretaje de valores y commodities n.c.p."
    },
    {
        id: 712009,
        name: "Otros servicios de ensayos y análisis técnicos (excepto actividades de plantas de revisión técnica)"
    },
    {
        id: 799000,
        name: "Otros servicios de reservas y actividades conexas (incluye venta de entradas para teatro, y otros)"
    },
    {
        id: 611090,
        name: "Otros servicios de telecomunicaciones alámbricas n.c.p."
    },
    {
        id: 612090,
        name: "Otros servicios de telecomunicaciones inalámbricas n.c.p."
    },
    {
        id: 613090,
        name: "Otros servicios de telecomunicaciones por satélite n.c.p."
    },
    {
        id: 461009,
        name: "Otros tipos de corretajes o remates al por mayor n.c.p."
    },
    {
        id: 854909,
        name: "Otros tipos de enseñanza n.c.p."
    },
    {
        id: 641990,
        name: "Otros tipos de intermediación monetaria n.c.p."
    },
    {
        id: 960200,
        name: "Peluquería y otros tratamientos de belleza"
    },
    {
        id: 31200,
        name: "Pesca de agua dulce"
    },
    {
        id: 31120,
        name: "Pesca marítima artesanal"
    },
    {
        id: 31110,
        name: "Pesca marítima industrial, excepto de barcos factoría"
    },
    {
        id: 631200,
        name: "Portales web"
    },
    {
        id: 431200,
        name: "Preparación del terreno"
    },
    {
        id: 131100,
        name: "Preparación e hilatura de fibras textiles"
    },
    {
        id: 631100,
        name: "Procesamiento de datos, hospedaje y actividades conexas"
    },
    {
        id: 110402,
        name: "Producción de aguas minerales y otras aguas embotelladas"
    },
    {
        id: 102010,
        name: "Producción de harina de pescado"
    },
    {
        id: 602000,
        name: "Programación y transmisiones de televisión"
    },
    {
        id: 931901,
        name: "Promoción y organización de competencias deportivas"
    },
    {
        id: 612020,
        name: "Radiocomunicaciones móviles"
    },
    {
        id: 652000,
        name: "Reaseguros"
    },
    {
        id: 691004,
        name: "Receptores judiciales"
    },
    {
        id: 381100,
        name: "Recogida de desechos no peligrosos"
    },
    {
        id: 381200,
        name: "Recogida de desechos peligrosos"
    },
    {
        id: 23000,
        name: "Recolección de productos forestales distintos de la madera"
    },
    {
        id: 31130,
        name: "Recolección y extracción de productos marinos"
    },
    {
        id: 383001,
        name: "Recuperación y reciclamiento de desperdicios y desechos metálicos"
    },
    {
        id: 383009,
        name: "Recuperación y reciclamiento de otros desperdicios y desechos n.c.p."
    },
    {
        id: 383002,
        name: "Recuperación y reciclamiento de papel"
    },
    {
        id: 383003,
        name: "Recuperación y reciclamiento de vidrio"
    },
    {
        id: 352010,
        name: "Regasificación de gas natural licuado (gnl)"
    },
    {
        id: 841200,
        name: "Regulación de las actividades de organismos que prestan servicios sanitarios, educativos, culturales"
    },
    {
        id: 841300,
        name: "Regulación y facilitación de la actividad económica"
    },
    {
        id: 842100,
        name: "Relaciones exteriores"
    },
    {
        id: 331502,
        name: "Reparación de aeronaves y naves espaciales"
    },
    {
        id: 952200,
        name: "Reparación de aparatos de uso doméstico, equipo doméstico y de jardinería"
    },
    {
        id: 952100,
        name: "Reparación de aparatos electrónicos de consumo (incluye aparatos de televisión y radio)"
    },
    {
        id: 331501,
        name: "Reparación de buques, embarcaciones menores y estructuras flotantes"
    },
    {
        id: 952300,
        name: "Reparación de calzado y de artículos de cuero"
    },
    {
        id: 951100,
        name: "Reparación de computadores y equipo periférico"
    },
    {
        id: 951200,
        name: "Reparación de equipo de comunicaciones (incluye la reparación teléfonos celulares)"
    },
    {
        id: 331301,
        name: "Reparación de equipo de medición, prueba, navegación y control"
    },
    {
        id: 331400,
        name: "Reparación de equipo eléctrico (excepto reparación de equipo y enseres domésticos)"
    },
    {
        id: 331201,
        name: "Reparación de maquinaria agropecuaria y forestal"
    },
    {
        id: 331202,
        name: "Reparación de maquinaria metalúrgica, para la minería, extracción de petróleo y para la construcción"
    },
    {
        id: 331203,
        name: "Reparación de maquinaria para la elaboración de alimentos, bebidas y tabaco"
    },
    {
        id: 331204,
        name: "Reparación de maquinaria para producir textiles, prendas de vestir, artículos de cuero y calzado  "
    },
    {
        id: 952400,
        name: "Reparación de muebles y accesorios domésticos"
    },
    {
        id: 952900,
        name: "Reparación de otros efectos personales y enseres domésticos"
    },
    {
        id: 331509,
        name: "Reparación de otros equipos de transporte n.c.p., excepto vehículos automotores"
    },
    {
        id: 331309,
        name: "Reparación de otros equipos electrónicos y ópticos n.c.p."
    },
    {
        id: 331900,
        name: "Reparación de otros tipos de equipo"
    },
    {
        id: 331209,
        name: "Reparación de otro tipo de maquinaria y equipos industriales n.c.p."
    },
    {
        id: 331100,
        name: "Reparación de productos elaborados de metal"
    },
    {
        id: 182000,
        name: "Reproducción de grabaciones"
    },
    {
        id: 32130,
        name: "Reproducción y cría de moluscos, crustáceos y gusanos marinos"
    },
    {
        id: 651100,
        name: "Seguros de vida"
    },
    {
        id: 651210,
        name: "Seguros generales, excepto actividades de isapres"
    },
    {
        id: 452001,
        name: "Servicio de lavado de vehículos automotores"
    },
    {
        id: 801002,
        name: "Servicio de transporte de valores en vehículos blindados"
    },
    {
        id: 691002,
        name: "Servicio notarial"
    },
    {
        id: 960901,
        name: "Servicios de adiestramiento, guardería, peluquería, paseo de mascotas (excepto act. veterinarias)"
    },
    {
        id: 691009,
        name: "Servicios de arbitraje; síndicos de quiebra y peritos judiciales; otras actividades jurídicas n.c.p."
    },
    {
        id: 711001,
        name: "Servicios de arquitectura (diseño de edificios, dibujo de planos de construcción, entre otros)"
    },
    {
        id: 691001,
        name: "Servicios de asesoramiento y representación jurídica"
    },
    {
        id: 960320,
        name: "Servicios de cementerios"
    },
    {
        id: 24002,
        name: "Servicios de corta de madera a cambio de una retribución o por contrata"
    },
    {
        id: 24003,
        name: "Servicios de extinción y prevención de incendios forestales"
    },
    {
        id: 24001,
        name: "Servicios de forestación a cambio de una retribución o por contrata"
    },
    {
        id: 862031,
        name: "Servicios de médicos prestados de forma independiente"
    },
    {
        id: 862032,
        name: "Servicios de odontólogos prestados de forma independiente"
    },
    {
        id: 900001,
        name: "Servicios de producción de obras de teatro, conciertos, espectáculos de danza, otras prod. escénicas"
    },
    {
        id: 731001,
        name: "Servicios de publicidad prestados por empresas"
    },
    {
        id: 731002,
        name: "Servicios de publicidad prestados por profesionales"
    },
    {
        id: 742001,
        name: "Servicios de revelado, impresión y ampliación de fotografías"
    },
    {
        id: 801001,
        name: "Servicios de seguridad privada prestados por empresas"
    },
    {
        id: 801003,
        name: "Servicios de seguridad privada prestados por independientes"
    },
    {
        id: 749002,
        name: "Servicios de traducción e interpretación prestados por empresas"
    },
    {
        id: 492240,
        name: "Servicios de transporte a turistas"
    },
    {
        id: 492210,
        name: "Servicios de transporte de escolares"
    },
    {
        id: 492230,
        name: "Servicios de transporte de pasajeros en taxis libres y radiotaxis"
    },
    {
        id: 492220,
        name: "Servicios de transporte de trabajadores"
    },
    {
        id: 960310,
        name: "Servicios funerarios"
    },
    {
        id: 681020,
        name: "Servicios imputados de alquiler de viviendas"
    },
    {
        id: 854902,
        name: "Servicios personales de educación"
    },
    {
        id: 742003,
        name: "Servicios personales de fotografía "
    },
    {
        id: 749003,
        name: "Servicios personales de traducción e interpretación"
    },
    {
        id: 869092,
        name: "Servicios prestados de forma independiente por otros profesionales de la salud"
    },
    {
        id: 522130,
        name: "Servicios prestados por concesionarios de carreteras"
    },
    {
        id: 900004,
        name: "Servicios prestados por periodistas independientes"
    },
    {
        id: 711003,
        name: "Servicios profesionales de ingeniería y actividades conexas de consultoría técnica "
    },
    {
        id: 32140,
        name: "Servicios relacionados con la acuicultura marina"
    },
    {
        id: 31140,
        name: "Servicios relacionados con la pesca marítima"
    },
    {
        id: 742002,
        name: "Servicios y actividades de fotografía "
    },
    {
        id: 21002,
        name: "Silvicultura y otras actividades forestales (excepto explotación de viveros forestales)"
    },
    {
        id: 562100,
        name: "Suministro de comidas por encargo (servicios de banquetería)"
    },
    {
        id: 353001,
        name: "Suministro de vapor y de aire acondicionado"
    },
    {
        id: 562900,
        name: "Suministro industrial de comidas por encargo; concesión de servicios de alimentación "
    },
    {
        id: 131200,
        name: "Tejedura de productos textiles"
    },
    {
        id: 611010,
        name: "Telefonía fija"
    },
    {
        id: 611020,
        name: "Telefonía larga distancia"
    },
    {
        id: 612010,
        name: "Telefonía móvil celular"
    },
    {
        id: 613010,
        name: "Telefonía móvil satelital"
    },
    {
        id: 612030,
        name: "Televisión de pago inalámbrica"
    },
    {
        id: 611030,
        name: "Televisión de pago por cable"
    },
    {
        id: 613020,
        name: "Televisión de pago satelital"
    },
    {
        id: 433000,
        name: "Terminación y acabado de edificios"
    },
    {
        id: 351020,
        name: "Transmisión de energía eléctrica"
    },
    {
        id: 601000,
        name: "Transmisiones de radio"
    },
    {
        id: 501200,
        name: "Transporte de carga marítimo y de cabotaje"
    },
    {
        id: 492300,
        name: "Transporte de carga por carretera"
    },
    {
        id: 491200,
        name: "Transporte de carga por ferrocarril"
    },
    {
        id: 512000,
        name: "Transporte de carga por vía aérea"
    },
    {
        id: 502200,
        name: "Transporte de carga por vías de navegación interiores"
    },
    {
        id: 492250,
        name: "Transporte de pasajeros en buses interurbanos "
    },
    {
        id: 501100,
        name: "Transporte de pasajeros marítimo y de cabotaje"
    },
    {
        id: 511000,
        name: "Transporte de pasajeros por vía aérea"
    },
    {
        id: 502100,
        name: "Transporte de pasajeros por vías de navegación interiores"
    },
    {
        id: 492130,
        name: "Transporte de pasajeros vía taxi colectivo"
    },
    {
        id: 491100,
        name: "Transporte interurbano de pasajeros por ferrocarril"
    },
    {
        id: 493020,
        name: "Transporte por gasoductos"
    },
    {
        id: 493010,
        name: "Transporte por oleoductos"
    },
    {
        id: 492120,
        name: "Transporte urbano y suburbano de pasajeros vía locomoción colectiva"
    },
    {
        id: 492110,
        name: "Transporte urbano y suburbano de pasajeros vía metro y metrotren"
    },
    {
        id: 16400,
        name: "Tratamiento de semillas para propagación"
    },
    {
        id: 382100,
        name: "Tratamiento y eliminación de desechos no peligrosos"
    },
    {
        id: 382200,
        name: "Tratamiento y eliminación de desechos peligrosos"
    },
    {
        id: 259200,
        name: "Tratamiento y revestimiento de metales; maquinado"
    },
    {
        id: 462020,
        name: "Venta al por mayor de animales vivos"
    },
    {
        id: 464904,
        name: "Venta al por mayor de artículos de papelería y escritorio"
    },
    {
        id: 464903,
        name: "Venta al por mayor de artículos de perfumería, de tocador y cosméticos"
    },
    {
        id: 464902,
        name: "Venta al por mayor de artículos eléctricos y electrónicos para el hogar"
    },
    {
        id: 463020,
        name: "Venta al por mayor de bebidas alcohólicas y no alcohólicas"
    },
    {
        id: 463012,
        name: "Venta al por mayor de carne y productos cárnicos"
    },
    {
        id: 466100,
        name: "Venta al por mayor de combustibles sólidos, líquidos y gaseosos y productos conexos"
    },
    {
        id: 465100,
        name: "Venta al por mayor de computadores, equipo periférico y programas informáticos"
    },
    {
        id: 466902,
        name: "Venta al por mayor de desechos metálicos (chatarra)"
    },
    {
        id: 466909,
        name: "Venta al por mayor de desperdicios, desechos y otros productos n.c.p."
    },
    {
        id: 464906,
        name: "Venta al por mayor de diarios y revistas"
    },
    {
        id: 465905,
        name: "Venta al por mayor de equipo de transporte(excepto vehículos automotores, motocicletas y bicicletas)"
    },
    {
        id: 465200,
        name: "Venta al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones"
    },
    {
        id: 463011,
        name: "Venta al por mayor de frutas y verduras"
    },
    {
        id: 463019,
        name: "Venta al por mayor de huevos, lácteos, abarrotes y de otros alimentos n.c.p."
    },
    {
        id: 464908,
        name: "Venta al por mayor de instrumentos científicos y quirúrgicos"
    },
    {
        id: 464905,
        name: "Venta al por mayor de libros"
    },
    {
        id: 466301,
        name: "Venta al por mayor de madera en bruto y productos primarios de la elaboración de madera"
    },
    {
        id: 465300,
        name: "Venta al por mayor de maquinaria, equipo y materiales agropecuarios"
    },
    {
        id: 465901,
        name: "Venta al por mayor de maquinaria metalúrgica, para la minería, extracción de petróleo y construcción"
    },
    {
        id: 465902,
        name: "Venta al por mayor de maquinaria para la elaboración de alimentos, bebidas y tabaco"
    },
    {
        id: 465903,
        name: "Venta al por mayor de maquinaria para la industria textil, del cuero y del calzado"
    },
    {
        id: 465904,
        name: "Venta al por mayor de maquinaria y equipo de oficina; venta al por mayor de muebles de oficina"
    },
    {
        id: 466302,
        name: "Venta al por mayor de materiales de construcción, artículos de ferretería, gasfitería y calefacción"
    },
    {
        id: 462010,
        name: "Venta al por mayor de materias primas agrícolas"
    },
    {
        id: 466200,
        name: "Venta al por mayor de metales y minerales metalíferos"
    },
    {
        id: 464901,
        name: "Venta al por mayor de muebles, excepto muebles de oficina"
    },
    {
        id: 462090,
        name: "Venta al por mayor de otras materias primas agropecuarias n.c.p."
    },
    {
        id: 464909,
        name: "Venta al por mayor de otros enseres domésticos n.c.p."
    },
    {
        id: 465909,
        name: "Venta al por mayor de otros tipos de maquinaria y equipo n.c.p."
    },
    {
        id: 463014,
        name: "Venta al por mayor de productos de confitería"
    },
    {
        id: 463013,
        name: "Venta al por mayor de productos del mar (pescados, mariscos y algas)"
    },
    {
        id: 464907,
        name: "Venta al por mayor de productos farmacéuticos y medicinales "
    },
    {
        id: 466901,
        name: "Venta al por mayor de productos químicos"
    },
    {
        id: 464100,
        name: "Venta al por mayor de productos textiles, prendas de vestir y calzado"
    },
    {
        id: 463030,
        name: "Venta al por mayor de tabaco"
    },
    {
        id: 451001,
        name: "Venta al por mayor de vehículos automotores"
    },
    {
        id: 469000,
        name: "Venta al por mayor no especializada"
    },
    {
        id: 478100,
        name: "Venta al por menor de alimentos, bebidas y tabaco en puestos de venta y mercados (incluye ferias)"
    },
    {
        id: 472101,
        name: "Venta al por menor de alimentos en comercios especializados (almacenes pequeños y minimarket)"
    },
    {
        id: 477391,
        name: "Venta al por menor de alimento y accesorios para mascotas en comercios especializados"
    },
    {
        id: 477401,
        name: "Venta al por menor de antigüedades en comercios"
    },
    {
        id: 475909,
        name: "Venta al por menor de aparatos eléctricos, textiles para el hogar y otros enseres domésticos n.c.p."
    },
    {
        id: 477392,
        name: "Venta al por menor de armas y municiones en comercios especializados"
    },
    {
        id: 476301,
        name: "Venta al por menor de artículos de caza y pesca en comercios especializados"
    },
    {
        id: 475201,
        name: "Venta al por menor de artículos de ferretería y materiales de construcción "
    },
    {
        id: 477394,
        name: "Venta al por menor de artículos de joyería, bisutería y relojería en comercios especializados"
    },
    {
        id: 476103,
        name: "Venta al por menor de artículos de papelería y escritorio en comercios especializados"
    },
    {
        id: 477203,
        name: "Venta al por menor de artículos de perfumería, de tocador y cosméticos en comercios especializados"
    },
    {
        id: 477393,
        name: "Venta al por menor de artículos ópticos en comercios especializados"
    },
    {
        id: 477202,
        name: "Venta al por menor de artículos ortopédicos en comercios especializados"
    },
    {
        id: 472200,
        name: "Venta al por menor de bebidas alcohólicas y no alcohólicas en comercios especializados (botillerías)"
    },
    {
        id: 476302,
        name: "Venta al por menor de bicicletas y sus repuestos en comercios especializados"
    },
    {
        id: 477101,
        name: "Venta al por menor de calzado en comercios especializados"
    },
    {
        id: 477395,
        name: "Venta al por menor de carbón, leña y otros combustibles de uso doméstico en comercios especializados"
    },
    {
        id: 477103,
        name: "Venta al por menor de carteras, maletas y otros accesorios de viaje en comercios especializados"
    },
    {
        id: 473000,
        name: "Venta al por menor de combustibles para vehículos automotores en comercios especializados"
    },
    {
        id: 474100,
        name: "Venta al por menor de computadores, equipo periférico, programas informáticos y equipo de telecom."
    },
    {
        id: 476102,
        name: "Venta al por menor de diarios y revistas en comercios especializados"
    },
    {
        id: 474200,
        name: "Venta al por menor de equipo de sonido y de video en comercios especializados"
    },
    {
        id: 477397,
        name: "Venta al por menor de flores, plantas, arboles, semillas y abonos en comercios especializados"
    },
    {
        id: 477310,
        name: "Venta al por menor de gas licuado en bombonas (cilindros) en comercios especializados"
    },
    {
        id: 476200,
        name: "Venta al por menor de grabaciones de música y de video en comercios especializados"
    },
    {
        id: 475902,
        name: "Venta al por menor de instrumentos musicales en comercios especializados"
    },
    {
        id: 476400,
        name: "Venta al por menor de juegos y juguetes en comercios especializados"
    },
    {
        id: 476101,
        name: "Venta al por menor de libros en comercios especializados"
    },
    {
        id: 477398,
        name: "Venta al por menor de mascotas en comercios especializados"
    },
    {
        id: 475901,
        name: "Venta al por menor de muebles y colchones en comercios especializados"
    },
    {
        id: 477409,
        name: "Venta al por menor de otros artículos de segunda mano en comercios n.c.p."
    },
    {
        id: 476309,
        name: "Venta al por menor de otros artículos y equipos de deporte n.c.p."
    },
    {
        id: 477399,
        name: "Venta al por menor de otros productos en comercios especializados n.c.p."
    },
    {
        id: 478900,
        name: "Venta al por menor de otros productos en puestos de venta y mercados (incluye ferias)"
    },
    {
        id: 475202,
        name: "Venta al por menor de pinturas, barnices y lacas en comercios especializados"
    },
    {
        id: 477102,
        name: "Venta al por menor de prendas y accesorios de vestir en comercios especializados"
    },
    {
        id: 475203,
        name: "Venta al por menor de productos de vidrio en comercios especializados"
    },
    {
        id: 477201,
        name: "Venta al por menor de productos farmacéuticos y medicinales en comercios especializados"
    },
    {
        id: 478200,
        name: "Venta al por menor de productos textiles, prendas de vestir y calzado en puestos de venta y mercados"
    },
    {
        id: 477396,
        name: "Venta al por menor de recuerdos, artesanías y artículos religiosos en comercios especializados"
    },
    {
        id: 477402,
        name: "Venta al por menor de ropa usada en comercios"
    },
    {
        id: 472300,
        name: "Venta al por menor de tabaco y productos de tabaco en comercios especializados"
    },
    {
        id: 475300,
        name: "Venta al por menor de tapices, alfombras y cubrimientos para paredes y pisos"
    },
    {
        id: 475100,
        name: "Venta al por menor de telas, lanas, hilos y similares en comercios especializados"
    },
    {
        id: 451002,
        name: "Venta al por menor de vehículos automotores nuevos o usados (incluye compraventa)"
    },
    {
        id: 471100,
        name: "Venta al por menor en comercios de alimentos, bebidas o tabaco (supermercados e hipermercados)"
    },
    {
        id: 471910,
        name: "Venta al por menor en comercios de vestuario y productos para el hogar (grandes tiendas)"
    },
    {
        id: 472102,
        name: "Venta al por menor en comercios especializados de carne y productos cárnicos "
    },
    {
        id: 472103,
        name: "Venta al por menor en comercios especializados de frutas y verduras (verdulerías)"
    },
    {
        id: 472109,
        name: "Venta al por menor en comercios especializados de huevos, confites y productos alimenticios n.c.p."
    },
    {
        id: 472104,
        name: "Venta al por menor en comercios especializados de pescado, mariscos y productos conexos"
    },
    {
        id: 472105,
        name: "Venta al por menor en comercios especializados de productos de panadería y pastelería"
    },
    {
        id: 479902,
        name: "Venta al por menor mediante maquinas expendedoras"
    },
    {
        id: 479903,
        name: "Venta al por menor por comisionistas (no dependientes de comercios)"
    },
    {
        id: 479100,
        name: "Venta al por menor por correo, por internet y vía telefónica"
    },
    {
        id: 479901,
        name: "Venta al por menor realizada por independientes en la locomoción colectiva (ley 20.388)"
    },
    {
        id: 454001,
        name: "Venta de motocicletas"
    },
    {
        id: 454002,
        name: "Venta de partes, piezas y accesorios de motocicletas"
    },
    {
        id: 453000,
        name: "Venta de partes, piezas y accesorios para vehículos automotores"
    }
]